import React from 'react';
import TF from '../images/TF.png';

import {
  isMobile
} from "react-device-detect";

import { FiAlignJustify } from "react-icons/fi";
import { TfiShoppingCart, TfiShoppingCartFull } from "react-icons/tfi";

import {
  Drawer,
  Button,
  Placeholder,
  List,
} from 'rsuite';


class TFHeader extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
      isHasCart: false,
      open: false,
    };
  }

  componentDidMount() {
    this.checkCart();
  }

  checkCart = () => {
    let cart = localStorage.getItem("cart");
    if (!cart) return;
    let check = false;
    let _this = this;
    try {
      cart = JSON.parse(cart);
      Object.keys(cart).map((v)=>{
        if (cart[v] > 0) check = true;
      });

      if (check) _this.setState({ isHasCart: true });
    } catch(e) {

    }
  }

  setOpen = (status) => {
    this.setState({
      open: status,
    });
  }

  render() {
    let {nvg, aboutus, scope, shopping} = this.props;
    let {isHasCart, open} = this.state;
  	return (
  	  <header style={{ 
          backgroundColor: '#000', 
          paddingTop: 50, 
          paddingBottom: 50,
          position: 'fixed',
          top: 0,
          width: '100%',
          zIndex: 999
        }}>
          { !isMobile ?
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <div style={
              nvg ?
                {color: '#B87333', marginLeft: 20, marginRight: 20, width: 150, textAlign: 'center', fontSize: 16, fontWeight: 'bold' }
              :
                {color: '#fff', marginLeft: 20, marginRight: 20, width: 150, textAlign: 'center', cursor: 'pointer' }
            }
            onClick={()=>{
              window.location = '/nvg-lbt';
            }}
            >AI night vision</div>
            <div style={
              scope ? 
              {color: '#B87333', marginLeft: 20, marginRight: 20, width: 150, textAlign: 'center', fontSize: 16, fontWeight: 'bold' }
              :
              {color: '#fff', marginLeft: 20, marginRight: 20, width: 150, textAlign: 'center', cursor: 'pointer' }
              
            }
            onClick={()=>{
              window.location = '/scope';
            }}>AI Security Camera</div>
            <img src={TF} style={{ width: 150, paddingLeft: 20, cursor: 'pointer' }} onClick={()=>{ window.location = '/'; }}/>
            <div style={
              aboutus ? 
              {color: '#B87333', marginLeft: 20, marginRight: 20, width: 150, textAlign: 'center', fontSize: 16, fontWeight: 'bold' }
              :
              {color: '#fff', marginLeft: 20, marginRight: 20, width: 150, textAlign: 'center', cursor: 'pointer' }
            }
            onClick={()=>{
              window.location = '/aboutus';
            }}
            >About us</div>
            <div style={
              shopping? 
              {color: '#B87333', marginLeft: 20, marginRight: 20, width: 150, textAlign: 'center', fontSize: 16, fontWeight: 'bold' }
              :
              {color: '#fff', marginLeft: 20, marginRight: 20, width: 150, textAlign: 'center', cursor: 'pointer' }
            }
            onClick={()=>{
              window.location = '/shopping_cart';
            }}>{
              !isHasCart ? 
              <TfiShoppingCart style={{color: '#fff', fontSize: 36, marginRight: 20, cursor: 'pointer' }} onClick={()=>{ window.location = '/shopping_cart'; }}/>
              :
              <TfiShoppingCartFull style={{color: '#ffb300', fontSize: 36, marginRight: 20, cursor: 'pointer'}} onClick={()=>{ window.location = '/shopping_cart'; }}/>
            }</div>
          </div>
          :
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <FiAlignJustify 
              style={{ color: '#fff', fontSize: 50, marginLeft: 20 }} 
              onClick={()=>{
                this.setState({
                  open: !open,
                });
              }}
            />
            <img src={TF} style={{ width: 150, paddingLeft: 20, cursor: 'pointer' }} onClick={()=>{ window.location = '/'; }}/>
            {
              !isHasCart ? 
              <TfiShoppingCart style={{color: '#fff', fontSize: 50, marginRight: 20, cursor: 'pointer' }} onClick={()=>{ window.location = '/shopping_cart'; }}/>
              :
              <TfiShoppingCartFull style={{color: '#ffb300', fontSize: 50, marginRight: 20, cursor: 'pointer'}} onClick={()=>{ window.location = '/shopping_cart'; }}/>
            }
          </div>
        }

        <Drawer placement="left" open={open} onClose={() => this.setOpen(false)} style={{maxWidth: '100%'}}>
        <Drawer.Header>
          <Drawer.Title style={{ fontWeight: 'bold', color: '#000' }}>Menu</Drawer.Title>
          <Drawer.Actions>
            <Button onClick={() => this.setOpen(false)}>Close</Button>
          </Drawer.Actions>
        </Drawer.Header>
          <List>
            <List.Item style={
              {padding: '20px 60px', cursor: 'pointer', fontSize: 20}
            } onClick={()=>{ window.location = '/'; }}>Homepage</List.Item>
            
            <List.Item style={
              nvg ?
              {padding: '20px 60px', cursor: 'pointer', fontSize: 20, color: '#B87333'}
              :
              {padding: '20px 60px', cursor: 'pointer', fontSize: 20}
            } onClick={()=>{ window.location = '/nvg-lbt'; }}>AI Night Vision Goggles</List.Item>
            <List.Item style={
              scope?
              {padding: '20px 60px', cursor: 'pointer', fontSize: 20, color: '#B87333'}
              :
              {padding: '20px 60px', cursor: 'pointer', fontSize: 20}
            } onClick={()=>{ window.location = '/scope'; }}>AI Security Camera</List.Item>
            <List.Item style={
              aboutus?
              {padding: '20px 60px', cursor: 'pointer', fontSize: 20, color: '#B87333'}
              :
              {padding: '20px 60px', cursor: 'pointer', fontSize: 20}
            } onClick={()=>{ window.location = '/aboutus'; }}>About us</List.Item>
            <List.Item style={
              shopping ? 
              {padding: '20px 60px', cursor: 'pointer', fontSize: 20, color: '#B87333'}
              :
              {padding: '20px 60px', cursor: 'pointer', fontSize: 20}
            } onClick={()=>{ window.location = '/shopping_cart'; }}>Shopping Cart</List.Item>
          </List>
      </Drawer>
        </header>
  	);
  }
}
export default TFHeader;

import React from 'react';
import TF from '../images/TF.png';

import {
  isMobile
} from "react-device-detect";

class TFFooter extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
  	return (
  	  <footer style={
          isMobile ?
          { width: '100%', backgroundColor: '#333', height: 140, color: '#e0e0e0', display: 'flex', alignItems: 'center', justifyContent: 'center', ...this.props.style }
          :
          { width: '100%', backgroundColor: '#333', height: 90, color: '#e0e0e0', display: 'flex', alignItems: 'center', justifyContent: 'center', ...this.props.style }
        }>
            { isMobile ? 
              <div>
                <p style={{fontSize: 14, textAlign: 'center'}}>(c) 2024 Thunderfortis Inc. All rights reserved.</p>
                <p style={{fontSize: 14, textAlign: 'center'}}>sales@thunderfortis.com </p>
              </div>
            :
              <p style={{fontSize: 14}}>(c) 2024 RelaJet Inc. All rights reserved. | sales@thunderfortis.com | 台灣聯絡處: 台北市松山區光復北路37號10樓 | 洞見未來科技 </p>
            }
        </footer>
  	);
  }
}
export default TFFooter;

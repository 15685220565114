const React = require('react');
const ReactDOM = require('react-dom');

// import * as styles from './styles.css';
import TF from './images/TF.png';
import NVBG from './images/nvbg.jpg';
import CMOS from './images/CMOS.jpg';
import Scope from './images/scope.png';
import NV from './images/nv.png';
import Ivas from './images/ivas.jpg';
import RedDot from './images/red-dot.png';


import NVGLBT from './images/nvg-lbt.png';

import tfmp4 from './thunderfortis-en.mov';

import { AiOutlineAim } from "react-icons/ai";
import { GiNightSky } from "react-icons/gi";

import TFHeader from './libs/header.js'
import TFFooter from './libs/footer.js'

import { 
  Button,
  Container,
  Grid,
  Row,
  Col,
  Divider,
} from 'rsuite';

import 'rsuite/dist/rsuite.min.css';

import {
  isMobile
} from "react-device-detect";


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentWillMount() {
  
  }
  
  componentDidMount() {
  
  }
  
  render() {
  	return (
  		<div>
        <TFHeader />
  			<section style={{ 
          backgroundImage: `url(${NVBG})`, 
          height: 800,
          backgroundSize: 'cover',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}>          
          <h3 style={{ margin: 0, color: '#fff', fontSize: 60, textAlign: 'center', lineHeight: '1em' }}>Your AI professional solution</h3>
          <p style={{marginTop: 20, color: '#e3e3e3', fontSize: 20, textAlign: 'center', lineHeight: '1em'}}>Designed according to professional needs</p>
        </section>
        <Container style={{minHeight: 600, display: 'flex', justifyContent: 'center'}}>
          <Row className="show-grid" style={{height: '100%'}}>
            <Col md={20} mdOffset={2} style={{height: '100%'}}>
              <Col md={16} mdOffset={4} style={{marginTop: 40, marginBottom: 70}}>
                <h2 style={{color: '#333', textAlign: 'center', marginBottom: 30}}>Ultra Low Light solution Demo</h2>
                <video 
                  controls
                  src={tfmp4}
                  width={'100%'} 
                  autoplay
                ></video>
              </Col>
            </Col>
          </Row>
        </Container>
        <Container style={{minHeight: 600, display: 'flex', justifyContent: 'center'}}>
          <Row className="show-grid" style={{height: '100%'}}>
            <Col md={20} mdOffset={2} style={{height: '100%'}}>
              <Col md={20} mdOffset={2} style={
                isMobile ?
                { display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', padding: 20 }
                :
                { display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: '100%' }
              }>
                <Col md={14}>
                  <img src={Ivas} style={{ width: '60%', marginLeft: '20%' }} />
                </Col>
                <div>
                  <h2 style={{color: '#000'}}>AR+Thermal+NVG (IVAS Next)</h2>
                  <h3>The fastest professional NVG to get started with.</h3>
                  <h3>Key feature</h3>
                  <ul>
                    <li>Only low light sensor, under 0.5 millilux moonless starlight night vision</li>
                    <li>In fusion mode, can be 0 millilux moonless starlight.</li>
                    <li>AI low light enhancement</li>
                    <li>Strong light suppression</li>
                    <li>Thermal: ~40mK, 640x512 high resolution</li>
                    <li>1Km Raser Rangefinder.</li>
                    <li>Supports the function of turning the screen off (standby) by flipping it upward.</li>
                    <li>MIL-STD 810</li>
                  </ul>
                  <h3>Applications</h3>
                  <ul>
                    <li>i. Night vision goggles</li>
                    <li>ii. Day-Night Surveillance</li>
                    <li>iii. Vehicle Situation Awareness</li>
                    <li>iv. UAV & Robotic Navigation</li>
                    <li>v. Search & Rescue</li>
                  </ul>
                  <Divider />
                  <Button color="yellow" appearance="ghost" 
                  disabled
                  onClick={()=>{window.location = '/nvg-lbt'}}
                  style={{ width: '100%', height: 50, fontSize: 20, color: '#B87333', border: '2px solid #B87333', backgroundColor: '#fff'}}>
                    Please contact us by e-mail.
                  </Button>
                </div>
              </Col>
            </Col>
          </Row>
        </Container>
        <Container style={{minHeight: 600, display: 'flex', justifyContent: 'center'}}>
          <Row className="show-grid" style={{height: '100%'}}>
            <Col md={20} mdOffset={2} style={{height: '100%'}}>
              <Col md={20} mdOffset={2} style={
                isMobile ?
                { display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', padding: 20 }
                :
                { display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: '100%' }
              }>
                <Col md={12}>
                  <img src={NVGLBT} style={{ width: '60%', marginLeft: '20%' }} />
                </Col>
                <div>
                  <h2 style={{color: '#000'}}>AI NVG (Large view window)</h2>
                  <h3>Binocular & thermal night vision goggles</h3>
                  <h3>Key feature</h3>
                  <ul>
                    <li>Under 1 millilux moonless starlight night vision</li>
                    <li>Strong light suppression</li>
                    <li>High resolution video recording</li>
                    <li>Ultra low latency</li>
                    <li>Ultra low power</li>
                    <li>Flexible helmet mounting options with a standard dovetail interface</li>
                  </ul>
                  <h3>Applications</h3>
                  <ul>
                    <li>i. Night vision goggles</li>
                    <li>ii. Day-Night Surveillance</li>
                    <li>iii. Vehicle Situation Awareness</li>
                    <li>iv. UAV & Robotic Navigation</li>
                    <li>v. Search & Rescue</li>
                  </ul>
                  <Divider />
                  <Button color="yellow" appearance="ghost" 
                  onClick={()=>{window.location = '/nvg-lbt'}}
                  style={{ width: '100%', height: 50, fontSize: 20, color: '#B87333', border: '2px solid #B87333', backgroundColor: '#fff'}}>
                    Detail
                  </Button>
                </div>
              </Col>
            </Col>
          </Row>
        </Container>
        <Container style={{minHeight: 600, display: 'flex', justifyContent: 'center'}}>
          <Row className="show-grid" style={{height: '100%'}}>
            <Col md={20} mdOffset={2} style={{height: '100%'}}>
              <Col md={20} mdOffset={2} style={
                isMobile ?
                { display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', padding: 20 }
                :
                { display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: '100%' }
              }>
                <Col md={12}>
                  <img src={RedDot} style={{ width: '60%', marginLeft: '20%' }} />
                </Col>
                <div>
                  <h2 style={{color: '#000'}}>Red-Dot Themral GunSight</h2>
                  <h3>Red-Dot formfactor, and high-resolution.</h3>
                  <h3>Key feature</h3>
                  <ul>
                    <li>640x512 High-resolution Thermal</li>
                    <li>Red and Green aim mark</li>
                    <li>Ultra low latency</li>
                    <li>Ultra low power</li>
                    <li>Conventional sight mount</li>
                    <li>MIL-STD 810</li>
                  </ul>
                  <h3>Applications</h3>
                  <ul>
                    <li>i. Targeting</li>
                    <li>ii. Day-Night Surveillance</li>
                    <li>iii. Vehicle Situation Awareness</li>
                    <li>iv. UAV & Robotic Navigation</li>
                    <li>v. Search & Rescue</li>
                  </ul>
                  <Divider />
                  <Button color="yellow" appearance="ghost" 
                  disabled
                  onClick={()=>{window.location = '/nvg-lbt'}}
                  style={{ width: '100%', height: 50, fontSize: 20, color: '#B87333', border: '2px solid #B87333', backgroundColor: '#fff'}}>
                    Please contact us by e-mail.
                  </Button>
                </div>
              </Col>
            </Col>
          </Row>
        </Container>
        <Container style={{minHeight: 600, display: 'flex', justifyContent: 'center'}}>
          <Row className="show-grid" style={{height: '100%'}}>
            <Col md={20} mdOffset={2} style={{height: '100%'}}>
              <Col md={20} mdOffset={2} style={
                isMobile ?
                { display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', padding: 20 }
                :
                { display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: '100%' }
              }>
                <Col md={12}>
                  <img src={NV} style={{ width: '100%' }} />
                </Col>
                <div>
                  <h2 style={{color: '#000'}}>AI NVG</h2>
                  <h3>Entry-Level and Single eye</h3>
                  <h3>Key feature</h3>
                  <ul>
                    <li>Under 1 millilux moonless starlight night vision</li>
                    <li>AI low light enhancement</li>
                    <li>Strong light suppression</li>
                    <li>High resolution video recording</li>
                    <li>Ultra low latency</li>
                    <li>Ultra low power</li>
                    <li>Flexible helmet mounting options with a standard dovetail interface</li>
                  </ul>
                  <h3>Applications</h3>
                  <ul>
                    <li>i. Night vision goggles</li>
                    <li>ii. Day-Night Surveillance</li>
                    <li>iii. Vehicle Situation Awareness</li>
                    <li>iv. UAV & Robotic Navigation</li>
                    <li>v. Search & Rescue</li>
                  </ul>
                  <Divider />
                  <Button color="yellow" appearance="ghost" 
                  onClick={()=>{window.location = '/nvg'}}
                  style={{ width: '100%', height: 50, fontSize: 20, color: '#B87333', border: '2px solid #B87333', backgroundColor: '#fff'}}>
                    Detail
                  </Button>
                </div>
              </Col>
            </Col>
          </Row>
        </Container>
        <Container style={{minHeight: 600, display: 'flex', justifyContent: 'center'}}>
          <Row className="show-grid">
            <Col md={20} mdOffset={2}>
              <Col md={20} mdOffset={2} style={
               isMobile ?
                { display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', padding: 20 }
                :
                { display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: '100%' }    
              }>
                { /* <GiNightSky /> */ }
                <Col md={12}>
                <img src={Scope} style={{ width: '100%' }}/>
                </Col>
                <div>
                  <h2 style={{color: '#000'}}>AI Security Camera</h2>
                  <h3>Key feature</h3>
                  <ul>
                    <li>A camera combine high magnification(1.5x-18x) and video recording</li>
                    <li>No jello effect</li>
                    <li>Viewing window for quick targeting</li>
                    <li>AI image recognition assisted targeting</li>
                    <li>Supports various wireless transmissions</li>
                  </ul>
                  <h3>Applications</h3>
                  <ul>
                    <li>i. Targeting</li>
                    <li>ii. Day-Night Surveillance</li>
                    <li>iii. Vehicle Situation Awareness</li>
                    <li>iv. UAV & Robotic Navigation</li>
                    <li>v. Search & Rescue</li>
                  </ul>
                  <Divider />
                  <Button 
                  onClick={()=>{window.location = '/scope'}}
                  color="yellow" appearance="ghost" style={{width: '100%', height: 50, fontSize: 20, color: '#B87333', border: '2px solid #B87333', backgroundColor: '#fff'}}>
                    Detail
                  </Button>
                </div>
              </Col>
            </Col>
          </Row>
        </Container>
        <Container style={{height: 400, backgroundColor: '#e0e0e0'}}>
          <Row className="show-grid" style={{height: '100%'}}>
            <Col md={20} mdOffset={2} style={{height: '100%'}}>
              <Col md={20} mdOffset={2} style={{display: 'flex', justifyContent: 'center', alignItems: 'center',  flexDirection: 'column', height: '100%', padding: 20 }}>
                <h3 style={{textAlign: 'center', marginBottom: 20}}>About Thunderfortis Inc.</h3>
                <p style={{ textAlign: 'center' }}>Thunderfortis is an experienced company developing AI tactical | Rescue | Security equipment, committed to providing better technology for security or special units. In addition to our rich practical experience, we are also a team composed of high-tech talents, including semiconductors and AI algorithms, focusing on the research and development of advanced technologies for special needs. We care deeply about using technology to ultimately protect the lives, safety and survivability of our end-users.</p>
                <Button color="yellow" appearance="ghost" style={{marginTop: 30, width: 200, height: 50, fontSize: 20, color: '#B87333', border: '2px solid #B87333', backgroundColor: '#fff'}}
                  onClick={()=>{
                    window.location = '/aboutus';
                  }}
                >
                  About Us
                </Button>
              </Col>
            </Col>
          </Row>
        </Container>
        <TFFooter />
  		</div>
  	);
  }
}

ReactDOM.render(
  <App name="John" />,
  document.getElementById('app')
);